export default {
  methods: {
    handleError(e) {
      console.log(e)
      this.$store.dispatch('notLoading')
      const msg_error = e?.response?.data.error || e.message
      if (msg_error == "Network Error")
        return this.$router.push('/servico-indisponivel')
      this.$Swal.fire({
        title: msg_error,
        icon: "warning"
      })
    }
  }
}