<template>
  <div @click.stop="" class="modal">
    <span @click="toggleInfo" class="close" title="Fechar campo">
      <icone-fechar />
    </span>
          
    <div id="area_edicao">
      <div>
        <div>
          <h2>{{aviso.titulo}}</h2>
          <p>{{stringifyDate(aviso.data)}}</p>
        </div>
      </div>

      <div>
          <h3 class="sr-only" style="margin-right: 5px">Por: </h3>
          <span>{{aviso.autor.nome}} ({{aviso.autor.setor}})</span>
      </div>

      <div>
        <p id="p_mensagem_aviso"></p>
      </div>
    </div>

      <div 
        v-if="$store.state.user.permissoes.avisos"
        style="margin-top: 2rem"
      >
        <my-button icone="delete" texto="Deletar" @buttonClick="toggleDeleteAviso()" />
      </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import handleError from '@/mixins/handleError'

import { pegar_DATA_HORA } from '@/utils/dates'

import Button from '@/components/Buttons/Button.vue'
import IconeFechar from '@/assets/SVGs/iconeFechar.vue'

export default {
  components: {
    'icone-fechar': IconeFechar,
    'my-button': Button,
  },
  props: {
    aviso: {
      type: Object,
      required: true
    }
  },
  mixins: [handleError],

  mounted() {
    let element = document.querySelector('#p_mensagem_aviso')
    element.innerHTML = this.urlify(this.aviso.mensagem, 30)
  },

  methods: {
    stringifyDate(data) {
      return pegar_DATA_HORA(data)
    },
    toggleInfo() {
      this.$emit('toggleInfoAviso', { aviso: {} })
    },

    urlify(text, size=20) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return `<a href="${url}" target="_blank">${url.substring(0, size)}${ url.length > 20 ? '...' : ''}</a>`;
      })
    },

    toggleDeleteAviso() {
      this.$Swal.fire({
        title: 'Deletar aviso',
        text: `Tem certeza que quer deletar o aviso '${this.aviso.titulo}'?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (!result.isConfirmed) return
        try {
					EventService.deleteAviso(this.aviso)
					.then(() => {
						this.$store.dispatch('notLoading')
            this.$emit('deletedAviso', { aviso: this.aviso })
						this.$Swal.fire({
								title: "Operação de deleção concluída!",
								icon: "success"
						})
					})
					.catch(this.handleError)
        } catch(e) {
          this.handleError(e)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'Aviso.scss';
@import '../Info.scss';
</style>