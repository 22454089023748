<template>
  <main>
    <header-login :titulo="titulo" />

    <router-view/>

    <footer-login />
  </main>
</template>

<script>
import Header from '@/components/HeaderFooterLogin/HeaderLogin.vue'
import Footer from '@/components/HeaderFooterLogin/FooterLogin.vue'

export default {
  name: 'Login',
  components: {
    'header-login': Header,
    'footer-login': Footer,
  },
  data() {
    return {
      titulo: ""
    }
  },
  created() {
    const fullURL = window.location.pathname
    this.titulo = fullURL.indexOf('senha') == -1 ? 'LOGIN CENTRAL' : 'RECUPERAR SENHA'
  },
  beforeUpdate() {
    const fullURL = window.location.pathname
    this.titulo = fullURL.indexOf('senha') == -1 ? 'LOGIN CENTRAL' : 'RECUPERAR SENHA'
  }
}
</script>