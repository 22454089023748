import axios from 'axios'
import { getToken } from '../auth'

const api = axios.create({
	baseURL: process.env.NODE_ENV == 'local' ? 
		'http://localhost:3600/' : 
		'https://api-central.thestartlaw.app/',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(async (config) => {
	const token = getToken();
	if (token) config.headers.Authorization = token;
	return config;
});

export default api