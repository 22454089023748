<template>
  <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="user-tie" role="img" aria-label="Ícone Editar perfil"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="">
                    <g class="fa-group">
                        <path fill="currentColor" d="M191.35 414.77L208 344l-32-56h96l-32 56 16.65 70.77L224 480zM224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128z" 
                              class="fa-secondary"></path>
                        <path fill="currentColor" d="M319.8 288.6L224 480l-95.8-191.4C56.9 292 0 350.3 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z" 
                              class="fa-primary"></path>
                    </g>
                </svg>
</template>