<template>
  <div class="area_icone">
    <div class="botao_svg" :title="icone">
      <icone-abertura v-if="icone == 'Abertura'"/>
      <icone-check v-else-if="icone == 'Em andamento'"/>
      <icone-concluida v-else-if="icone == 'Concluída'"/>
    </div>    
  </div>
</template>

<script>
import iconeAbertura from '@/assets/SVGs/iconeAbertura.vue'
import iconeCheck from '@/assets/SVGs/iconeCheck.vue'
import iconeConcluida from '@/assets/SVGs/iconeConcluida.vue'

export default {
  components: {
    'icone-abertura': iconeAbertura,
    'icone-check': iconeCheck,
    'icone-concluida': iconeConcluida,
  },

  props: {
    icone: {
      mandatory: true,
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Icones.scss';
</style>