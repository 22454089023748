import api from './api'

export default {
	async login(event) {
		try {
			const { data } = await api.post('/sessions', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async validateToken() {
		try {
			const { data } = await api.post('/sessions/validate')
			return data
		} catch (e) {
			throw (e)
		}
	},
	async passwordReset(event) {
		try {
			const { data } = await api.put('/users/reset-password', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
}