<template>
  <label>
    <input type="checkbox">
    <span></span>
  </label>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import 'Checkmark';
</style>