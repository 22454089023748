const handlers = {}

const User = {
	nome: '',
	email: '',
	senha: '',
	setor: '',
	permissoes: {
		usuarios: false,
		demandas: false,
		links: false,
		NPS: false,
		avisos: false,
	},
}

handlers.UserDatabase = User
handlers.UserAdd = {
	...User,
	senha_confirm: '',
}
handlers.UserEdit = {
	...User,
	id: '',
	senha_confirm: '',
}
handlers.UserLocalStorage = { 
  isLoggedIn: false,
  id: '',
	nome: '',
	email: '',
	setor: '',
	permissoes: {},
}
handlers.UserPerfil = { 
  id: '',
	nome: '',
	email: '',
}

const Link = {
	titulo: '', 
	url: '', 
	setores: {
		'Administração': false,
		'Comercial': false,
		'Comunicação': false,
		'Customer Success': false,
		'Financeiro': false,
		'Gestão de Pessoas': false,
		'Jurídico': false,
		'Tech': false,
	}
}

handlers.Link = Link

module.exports = handlers