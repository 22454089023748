<template>
  <div id="area_tabela">
    <table>
      <thead>
        <tr>
          <th v-if="!remover_check"></th>
          <th></th>
          <th>Título</th>
          <th>Autor</th>
          <!-- <th>Setores</th> -->
          <th>Status</th>
          <th>Prazo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(demanda) in demandas" 
          :key="demanda._id"
          v-show="filtro(demanda)"
        >
          <td v-if="!remover_check" class="acao">
            <label class="container_check">
              <input class="demanda_selecionada" :value="demanda._id" type="checkbox"
                v-model="selecionadas" @change="emmitChange"
              >
              <span class="checkmark"></span>
            </label>
          </td>

          <td class="acao">
            <div class="svg_acao" title="Abrir solicitação">
            <router-link :to="{ name: 'Demanda', params: { id: demanda._id }}">
              <icone-link />
            </router-link>
          </div>
          </td>

          <td>{{demanda.titulo}}</td>
          <td>{{demanda.autor.nome}}</td>
          <!-- <td>{{orderSetores(demanda.setores)}}</td> -->
          <td>
            <icones-demanda :icone="demanda.status" />
          </td>
          <td>
            <p
              :style="{ color: corStatus(demanda.prazo, demanda.status)}"
            >
              {{stringifyDate(demanda.prazo)}}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import datesMixins from '@/mixins/dates'
import filterDemanda from '@/mixins/filterDemanda'

import IconeLink from '@/assets/SVGs/iconeLink.vue'

import IconesDemandas from '@/components/Icones/Status/Demandas.vue'

export default {
  components: {
    'icone-link': IconeLink,
    'icones-demanda': IconesDemandas,
  },
  props: {
    demandas: {
      required: true,
      type: Array
    },
    remover_check: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      selecionadas: []
    }
  },
  mixins: [datesMixins, filterDemanda],
  methods: {
    emmitChange(event) {
      this.$emit('checkmarkChange', { id: event.target.value, state: event.target.checked })
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'Demandas.scss';
@import '../Tables.scss';
</style>