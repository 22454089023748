export default {
  methods: {
    toggleModal(data) {
			const { target } = data
			let value = !this.modalStates[target]

			this.modalStates[target] = value
		},
		toggleModalLocal(target) {
			this.modalStates[target] = !this.modalStates[target] 
		},
  }
}