export default {
  methods: {
    stringifyDate(data, offset) {
      let novo_offset = offset | 0
      const {pegar_DATA_HORA} = require('../utils/dates')
      return pegar_DATA_HORA(data, novo_offset)
    },
    corStatus(prazo, status) {
      if(status == 'Concluída') return 'green'
      
      const agora = new Date().getTime()
      const diferenca = agora - (new Date(prazo).getTime()+(3*3600000))

      //atrasada
      if(diferenca > 0) return 'red'
      //1 dia para terminar
      if(diferenca > - (3600000*24)) return '#d1d100'
      //tempo tranquilo
      return ''
    },
  }
}