import login from './EventService/login'
import avisos from './EventService/avisos'
import user from './EventService/user'
import links from './EventService/links'
import demandas from './EventService/demandas'

export default {
	...login,
	...avisos,
	...user,
	...links,
	...demandas,
}