<template>
	<div class="header_pagina">
		<h1>Perfil</h1>
	</div>

	<div class="modal">
		<div id="form">
			<form @submit.prevent="applyChanges">
				<div class="input-group">
					<label class="sr-only" for="email">E-mail</label>
					<input required
						v-model="user.email"
						autocomplete="off" maxlength="32" type="email" id="edit_email" name="email" placeholder="E-mail"
						oninput="document.getElementById('edit_email').style.backgroundColor = 'white'"
					>
				</div>

				<div class="input-group">
					<label class="sr-only" for="senha">Senha</label>
					<input autocomplete="off" 
						type="password" id="add_senha" name="senha" placeholder="Senha de login"
						v-model="user.senha" @input="confirmSenhas"
					>
					<small class="help">• Caso não deseje alterar a senha, mantenha os campos de senha vazios.</small>
				</div>

				<div class="input-group" id="area_da_senha2" v-if="user.senha">
					<label class="sr-only" for="edit_senha_confirmacao">Confirme a senha</label>
					<input autocomplete="off" 
						type="password" id="add_senha_confirm" name="senha_confirm" placeholder="Repita a senha"
						v-model="user.senha_confirm" @input="confirmSenhas"
					>
				</div>
				<small v-show="senhas_diferentes" id="aviso-senhas">A confirmação da senha está diferente!</small>

				<div class="input-group">
					<label class="sr-only" for="nome">Nome</label>
					<input required
						v-model="user.nome"
						autocomplete="off" maxlength="32" type="text" 
						id="edit_nome" name="nome" placeholder="Nome"
						oninput="document.getElementById('edit_nome').style.backgroundColor = 'white'"
					>
				</div>
        <p v-if="error">{{ error }}</p>

				<div class="input-group actions">
					<button class="button new">Aplicar alterações</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { UserPerfil } from '@/utils/schemas'

export default ({
	data() {
		return {
			user: { ...UserPerfil },
			senhas_diferentes: false,
			error: null,
		}
	},
	created() {
		this.user.id = this.$store.state.user.id
		this.user.nome = this.$store.state.user.nome
		this.user.email = this.$store.state.user.email
	},
	methods: {
		confirmSenhas() {
			const { senha, senha_confirm } = this.user
			if(!this.senhaPreenchida) {
				this.senhas_diferentes = false
				this.user.senha_confirm = ''
			}

			if(!senha_confirm || senha.length == 0 || senha_confirm.length == 0) return
			if(senha != senha_confirm) return this.senhas_diferentes = true
			return this.senhas_diferentes = false
		},
		applyChanges() {
			if(this.senhas_diferentes) 
				return this.$Swal.fire('Tenha certeza de que sua senha e a confirmação delas são iguais!')
			if(this.user.senha.length > 0 && this.user.senha_confirm?.length == 0)
				return this.$Swal.fire('Não se esqueça de confirmar sua nova senha!')

			const event = { ...this.user }
			this.$store.dispatch('loading')

			this.$store.dispatch('updateProfile', event)
			.then(() => {
				this.$store.dispatch('notLoading')

				this.user.senha = ''
				this.user.senha_confirm = ''
				this.senhas_diferentes = false

				this.$Swal.fire({
            title: "Perfil alterado!",
            icon: "success"
        })
			})
			.catch( error => {
				this.$store.dispatch('notLoading')
				const msg_error = error?.response?.data.error || error.message
					if (msg_error == "Network Error")
						return this.$router.push('/servico-indisponivel')
				this.$Swal.fire({
            title: msg_error,
            icon: "warning"
        })
				// return this.error = msg_error
			})
		},
	},
	computed: {
		senhaPreenchida() {
			return this.user.senha ? this.user.senha.length ? true : false : false
		}
	},
})
</script>


<style lang="scss" scoped>
@import "./Perfil.scss";
</style>