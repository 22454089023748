import api from './api'

export default {
	async fetchAvisos() {
		try {
			const { data } = await api.get('/avisos/all')
			return data
		} catch (e) {
			throw (e)
		}
	},
	async createAviso(event) {
		try {
			const { data } = await api.post('/avisos', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async deleteAviso(event) {
		try {
			const { data } = await api.delete(`/avisos?id=${event._id}`)
			return data
		} catch (e) {
			throw (e)
		}
	},
}