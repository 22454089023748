<template>
  <div id="aviso_modal">
    <div id="avisos">
      <transition name="fade" v-if="$store.state.user.permissoes.avisos">
        <modal-add v-if="modalStates.addAviso" @toggleAddAviso="toggleModal" @createdAviso="addAviso"/>
      </transition>

      <div id="header">
        <div>
          <button v-if="$store.state.user.permissoes.avisos"
            title="Adicionar aviso"
            class="clear_button"
            @click="toggleModalLocal('addAviso')" 
          >
            <icone-bandeira />
          </button>
          <icone-bandeira v-else />

          <span>Avisos</span>
        </div>
      </div>
      <div id="body">
        <div v-if="avisos.length == 0" id="vazio">
          <p>Nenhum aviso atual</p>
        </div>
        <div v-else>
          <div class="aviso" v-for="aviso in avisos" :key="aviso._id"
            :title="aviso.titulo"
            :style="{ 
              background: setoresColors[aviso.autor.setor],
              opacity: avisoSelecionado._id == aviso._id ? 1 : '',
              'font-weight': avisoSelecionado._id == aviso._id ? 600 : ''  
            }"
            @click="passAviso(aviso)"
          >
            <p class="autor">
              {{aviso.autor.nome}}
            </p>
            <span> </span>
            <p class="data">
              {{stringifyDate(aviso.data)}}
            </p>
            <span> </span>
            <p class="titulo"
            >
              {{aviso.titulo}}
            </p>
          </div>
        </div>
      </div>
    </div>
            
    <transition name="slide-fade">
      <info-aviso
        v-if="modalStates.infoAviso"
        @toggleInfoAviso="passAviso"
        @deletedAviso="removeAviso"
        :aviso="avisoSelecionado"
      />
    </transition>
  </div>
</template>

<script>
import socket from "@/services/socket";

import EventService from "@/services/EventService";
import handleError from '@/mixins/handleError'
import { pegar_DATA_HORA } from '@/utils/dates'

import setoresColors from '@/utils/colors'
import toggleModals from '@/mixins/toggleModals'

import AddAviso from '../../../../components/Modals/Avisos/Add.vue'
import InfoAviso from '@/components/Info/Aviso/Aviso.vue'

import iconeBandeira from '@/assets/SVGs/iconeBandeira.vue'

export default {
  components: {
    'icone-bandeira': iconeBandeira,
    'modal-add': AddAviso,
    'info-aviso': InfoAviso,
  },
  data() {
    return {
      socketInstance: {},

      loading: true,
      error: null,
      avisos: [],
      avisoSelecionado: {},

			modalStates: {
				addAviso: false,
        infoAviso: false,
			},

      setoresColors
    }
  },
  mixins: [handleError, toggleModals],

  async mounted() {
    try {
      const response = await EventService.fetchAvisos()
      this.avisos = response.avisos

      this.loaded = true

      this.socketInstance = socket
      this.socketInstance.on(
        "aviso:postado", (data) => {
          if(data.socket_id == this.socketInstance.id) return
          delete data.socket_id
          this.avisos.unshift(data);
        }
      )
    } catch(e) {
      this.loaded = true
      console.log(e)
      handleError(e)
    }
  },

  methods: {
    stringifyDate(data) {
      return pegar_DATA_HORA(data).slice(0, 5)
    },
    addAviso(data) {
      this.avisos.unshift(data.aviso_criado)
      this.socketInstance.emit('aviso:iniciado', data.aviso_criado);
    },
    toggleDelete(aviso) {
      alert(`Quer deletar o aviso '${aviso.titulo}' (${aviso._id})?`)
    },

    passAviso(aviso) {
      if(!aviso._id || this.avisoSelecionado._id == aviso._id) {
        this.avisoSelecionado = {}
        return this.modalStates.infoAviso = false
      }
      
      if(!this.avisoSelecionado._id) {
        this.avisoSelecionado = aviso
        return this.modalStates.infoAviso = true
      }

      const diferentes = aviso._id != this.avisoSelecionado._id

      if(diferentes){
        this.modalStates.infoAviso = false
        setTimeout(() => {
          this.avisoSelecionado = aviso
          this.modalStates.infoAviso = true
        }, 300)
      }
    },

    removeAviso({ aviso }) {
      this.avisoSelecionado = {}
      this.modalStates.infoAviso = false

      this.avisos = this.avisos.filter(({_id}) => _id != aviso._id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'Avisos.scss';
</style>