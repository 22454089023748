<template>
  <div class="home">
    <div class="header_pagina">
      <h1>Home</h1>
    </div>

    <h2 v-if=error>{{error}}</h2>

    <section>
      <h2>Bem vinda(o) a central!</h2>
    </section>

    <section>
      <avisos/>
    </section>

    <section v-if="sections.demandas.autor.length != 0 || sections.demandas.responsavel.length != 0">
      <h2>Demandas</h2>
      <div id="secoes_demandas" >
        <div v-if="sections.demandas.autor.length != 0">
          <h3>Autor</h3>
          <table-demandas-autor 
            :demandas="sections.demandas.autor"
            :remover_check="true"  
          />
        </div>

        <div v-if="sections.demandas.responsavel.length != 0">
          <h3>Responsável</h3>
          <table-demandas-responsavel 
            :demandas="sections.demandas.responsavel"
            :remover_check="true"  
          />
        </div>
      </div>
    </section>

    <section id="links" v-if="sections.links.length != 0">
      <h2>Links</h2>
      <div id="div_botao">
        <div
          :style="{ background: setoresColors[$store.state.user.setor] }"
        >
          <button class="clear_button"
          >
            <p class="nome_setor">{{$store.state.user.setor}}</p>
          </button>
        </div>
      </div>
      <table-links 
        :links="sections.links"
        :remover_check="true"  
        :remove_filter="true"
      />
    </section>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import handleError from '@/mixins/handleError'

import TabelaAutor from '@/components/Tables/Demandas/Autor.vue'
import TabelaResponsavel from '@/components/Tables/Demandas/Responsavel.vue'
import TabelaLinks from '@/components/Tables/Links/Links.vue'

import avisos from './Avisos/Avisos.vue'

import setoresColors from '@/utils/colors'

export default {
  name: 'Home',
  components: {
    'avisos': avisos,
    'table-demandas-autor': TabelaAutor,
    'table-demandas-responsavel': TabelaResponsavel,
    'table-links': TabelaLinks,
  },
  data() {
    return {
      loading: true,
      error: null,
      avisos: [],
      
      sections: {
        demandas: {
          autor: [],
          responsavel: [],
        },
        links: []
      },
      setoresColors
    }
  },
  mixins: [handleError],
  async mounted() {
    try {
      const response_demandas = await EventService.fetchDemandasUser()
      this.sections.demandas.autor = response_demandas.demandas.filter(({ autor }) => autor._id == this.$store.state.user.id )
      this.sections.demandas.responsavel = response_demandas.demandas.filter(({ responsaveis }) => {
        const ids_responsaveis = responsaveis.map(({_id}) => _id)
        return ids_responsaveis.includes(this.$store.state.user.id)
      } )

      const response_links = await EventService.fetchLinksSetor()
      this.sections.links = response_links.links

      this.loaded = true
    } catch(e) {
      this.loaded = true
      console.log(e)
      handleError(e)
    }
  },
};
</script>

<style lang="scss" scoped>
@import '/Home.scss';
</style>