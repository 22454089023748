<template>
  <section class="container-info">
    <div id="informations">
      <div class="form-signin">
        <div class='area_logo'>
          <p id="titulo_agnes">CENTRAL STARTLAW</p>
          <!-- <img class='logo_apolo' src="https://thestartlaw.com/wp-content/uploads/2020/07/LOGO_APOLO_PRETA.png" alt="Logo Apolo"> -->
        </div>
        
        <p v-if="error">{{ error }}</p>

        <form @submit.prevent="onSubmit" class="form-area">
          <div class="form-group">
            <label class="control-label" for="email">E-mail</label>
            <input 
              placeholder="E-mail"
              v-model="loginData.email"
              @input="clearError"
              autocomplete="none" 
              id="email" 
              type="email" 
              class="form-control" 
              name="email" 
              required 
            >

            <label class="control-label_senha" for="senha">Senha</label>
            <input 
              placeholder="Senha"
              v-model="loginData.senha"
              @input="clearError"
              autocomplete="off" 
              id="senha" 
              type="password" 
              class="form-control" 
              name="senha" 
              required 
            >
          </div>

          <button type="submit" class="btn btn-lg btn-dark btn-block">Entrar</button>
        </form>

          <div class="reset_senha">
            <div class="area_reset_senha">
              <router-link to="/login/recuperar-senha">Esqueceu sua senha?</router-link>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from '@/components/HeaderFooterLogin/HeaderLogin.vue'
import Footer from '@/components/HeaderFooterLogin/FooterLogin.vue'

export default {
  name: 'Login',
  components: {
    'header-login': Header,
    'footer-login': Footer,
  },
  data () {
    return {
      loginData: {
        email: '',
        senha: '',
      },
      error: null,
    }
  },
  methods: {
	  onSubmit() {
      const event = { ...this.loginData }
		  this.$store.dispatch('loading')

      //TODO: Colocar setTimeout para mostrar o carregamento funcionando
      setTimeout(() => {
		    this.$store.dispatch('login', event)
        .then(() => {
          this.$store.dispatch('notLoading')
          this.$router.push('/')
        })
        .catch( error => {
          this.$store.dispatch('notLoading')
          const msg_error = error?.response?.data.error || error.message
			    if (msg_error == "Network Error")
            return this.$router.push('/servico-indisponivel')
          
          this.$Swal.mixin({
            toast: true,
            position: 'top',
            timer: 10000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$Swal.stopTimer)
              toast.addEventListener('mouseleave', this.$Swal.resumeTimer)
            }
          }).fire({
            icon: 'error',
            title: msg_error
          })
          // return this.error = msg_error
        })
      }, 1)
	  },
    clearError() {
      this.error = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '/Login.scss';
</style>