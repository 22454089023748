import api from './api'

export default {
	async fetchUsuarios(event) {
		try {
			const { data } = await api.get('/users/all', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async fetchSetores(event) {
		try {
			const { data } = await api.get('/users/all?setores=1', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async createUsuario(event) {
		try {
			const { data } = await api.post('/users', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async editUsuario(event) {
		try {
			const { data } = await api.put('/users', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async editPerfil(event) {
		try {
			const { data } = await api.put('/users/perfil', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async deleteUsuarios(event) {
		try {
			let query = `?`
			event.ids.forEach(id => query += `ids=${id}&`)
			const { data } = await api.delete(`/users${query}`)
			return data
		} catch (e) {
			throw (e)
		}
	},
}