import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

// PUBLIC
import tela404 from '../views/public/404/404.vue'
import telaOffline from '../views/public/Offline/Offline.vue'
import ResetPassword from '../views/public/ResetPassword/ResetPassword.vue';
import Login from '../views/public/Login/Login.vue';
import PreLogin from '../views/public/PreLogin/PreLogin.vue';

// Administrador, Staff e Cliente
import UserTemplate from '../views/private/UserTemplate/UserTemplate.vue';
import PrivateRoutes from './PrivateRoutes';

const routes = [
  {
    path: '/',
    name: 'LoggedIn',
    component: UserTemplate,
    meta: { requiresAuth: true },
    children: PrivateRoutes
  },
  {
    path: '/login',
    name: '',
    component: PreLogin,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
      },
      {
        path: 'recuperar-senha',
        name: 'Recuperar Senha',
        component: ResetPassword,
      }
    ]
  },
  {
    path: '/servico-indisponivel',
    name: 'Serviço Indisponível', 
    component: telaOffline
  },
  {
    path: '/:pathMatch(.*)*',
     name: 'Desconhecido', 
    component: tela404
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if(to.name == 'Serviço Indisponível')
    return next();

  const { getToken } = require('../services/auth')

  let token_local = !!getToken()

  const requires_token = to.matched.some((record) => record.meta.requiresAuth)
  const key = to.meta?.key

  document.title = `${to.name} - Central StartLaw`;

  if(!requires_token && !token_local) return next();

  try {
    //Valida o token e depois pega ele de novo
    await store.dispatch('validateToken')
    token_local = !!getToken()
  
    if (requires_token && !token_local) {
      document.title = `Login - Central StartLaw`;
      next('/login');
    }
    else if (!requires_token & token_local ||
      key && !store.state.user.permissoes[key]) {
      document.title = `Home - Central StartLaw`;
      next('/');
    }
    else next();
  } catch (e) {
    if(e.message == 'Network Error')
      return next('/servico-indisponivel');
    console.log(e)
    next('/login');
  }
});

export default router;
