<template>
  <svg
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
  >
    <g class="fa-group" transform="matrix(0.648623, 0, 0, 0.649269, 109.320717, -27.702238)" >
      <path
        fill="currentColor"
        d="M 811.59 390.734 L 811.59 433.447 C 811.59 445.242 802.028 454.803 790.233 454.803 L 704.808 454.803 L 704.808 540.229 C 704.808 552.025 695.245 561.586 683.451 561.586 L 640.738 561.586 C 628.943 561.586 619.382 552.025 619.382 540.229 L 619.382 454.803 L 533.956 454.803 C 522.16 454.803 512.599 445.242 512.599 433.447 L 512.599 390.734 C 512.599 378.94 522.16 369.377 533.956 369.377 L 619.382 369.377 L 619.382 283.951 C 619.382 272.157 628.943 262.595 640.738 262.595 L 683.451 262.595 C 695.245 262.595 704.808 272.157 704.808 283.951 L 704.808 369.377 L 790.233 369.377 C 802.028 369.377 811.59 378.94 811.59 390.734 Z"
        class="fa-primary"
      />
      <g class="fa-group" transform="matrix(1.5404, 0, 0, 1.538873, -168.516418, 42.627876)">
        <path
          fill="currentColor"
          d="M436 480h-20V24a24 24 0 0 0-24-24H56a24 24 0 0 0-24 24v456H12a12 12 0 0 0-12 12v20h448v-20a12 12 0 0 0-12-12zM128 76a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12zm0 96a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12zm52 148h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12zm76 160h-64v-84a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm64-172a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-96a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12V76a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12z"
          class="fa-secondary"
        />
      </g>
    </g>
  </svg>
</template>