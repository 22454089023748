<template>
  <div id="area_pop" :class="loadingState">
    <img src="@/assets/logo-branca.png" id="rodante">
		<!-- <button type="button" @click="resetLoading">Reset</button> -->
  </div>
</template>

<script>
export default {
	computed: {
		loadingState() {
		return this.$store.state.loading ? 'active' : 'inactive'
		}
	},
	methods: {
		resetLoading() {
			this.$store.dispatch('notLoading')
		}
	}
}
</script>

<style lang="scss">
@import '/LoadingLogo.scss';
</style>