import api from './api'

export default {
	async fetchLinks(event) {
		try {
			const { data } = await api.get('/links/all', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async fetchLinksSetor(event) {
		try {
			const { data } = await api.get('/links/setor', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async createLink(event) {
		try {
			const { data } = await api.post('/links', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async editLink(event) {
		try {
			const { data } = await api.put('/links', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async deleteLinks(event) {
		try {
			let query = `?`
			event.ids.forEach(id => query += `ids=${id}&`)
			const { data } = await api.delete(`/links${query}`)
			return data
		} catch (e) {
			throw (e)
		}
	},
}