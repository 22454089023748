<template>
	<div @dblclick="dblClickHandler" class="modal-overlay active">
		<div @click.stop="" class="modal">
			<span @click="toggleModal" class="close" title="Fechar campo">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" 
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" 
						class=""
				>
						<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
				</svg>
			</span>
						
			<div id="form">
				<h2 id="add_title">Adicionar aviso</h2>

				<form @submit.prevent="handleSubmit">
          <div class="input-group">
            <label class="sr-only" for="titulo">Título</label>
            <input required autocomplete="off" 
              type="text" id="add_titulo" name="titulo" placeholder="Título do aviso"
              v-model="modalData.titulo"
            >
          </div>

          <div class="input-group">
            <label class="sr-only" for="mensagem">Mensagem</label>
            <textarea required autocomplete="off" id="add_mensagem" 
              name="mensagem" placeholder="Mensagem do aviso"
              v-model="modalData.mensagem"
              cols="30" rows="5"
            >
            </textarea>
          </div>

					<div class="input-group actions">
						<button type="button" @click="toggleModal" class="button cancel clear_button">Cancelar</button>
						<button class="button new">Adicionar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import EventService from '@/services/EventService'
import handleError from '@/mixins/handleError'

export default {
  components: {
  },
  data() {
    return {
      modalData: {
        titulo: '',
        mensagem: ''
      },
    }
  },
  mixins: [handleError],
  methods: {
    toggleModal() {
      this.$emit('toggleAddAviso', { target: 'addAviso' })
    },
    dblClickHandler(e) {
      if(e.target.className === 'modal-overlay active')
        this.toggleModal()
    },
    handleSubmit() {
      const event = {
        ...this.modalData,
      }

      this.$store.dispatch('loading')

      EventService.createAviso(event)
      .then(({ aviso_criado }) => {
        this.clearOnCreate()
        this.$emit('createdAviso', { aviso_criado })
      })
      .catch(this.handleError)
    },
    clearOnCreate() {
      this.modalData = {
        titulo: '', 
        mensagem: '', 
      },

      this.$store.dispatch('notLoading')

      this.toggleModal()
      
      this.$Swal.fire({
          title: "Aviso adicionado!",
          icon: "success"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
</style>