<template>
  <div class="area_botao">
  <button :type="tipo ? tipo : 'button'" class="botao_svg clear_button" :title="texto" @click="emitirClick">
    <icone-add-empresa v-if="icone == 'add-empresa'" />
    <icone-add-user v-else-if="icone == 'add-user'" />
    <icone-add v-else-if="icone == 'add'" />
    <icone-delete v-else-if="icone == 'delete'" />
    <icone-download v-else-if="icone == 'download'" />
    <icone-link v-else-if="icone == 'link'" />
    <icone-edit v-else-if="icone == 'edit'" />
    <icone-concluida v-else-if="icone == 'concluida'" />
    <icone-check v-else-if="icone == 'check'" />

    <span class="text">{{ texto }}</span>
  </button>
  </div>
</template>

<script>
import iconeAddUser from '@/assets/SVGs/iconeAddUser.vue'
import iconeAddEmpresa from '@/assets/SVGs/iconeAddEmpresa.vue'

import iconeDelete from '@/assets/SVGs/iconeDelete.vue'
import iconeDownload from '@/assets/SVGs/iconeDownload.vue'
import iconeAdd from '@/assets/SVGs/iconeAdd.vue'
import iconeLink from '@/assets/SVGs/iconeLink.vue'
import iconeEdit from '@/assets/SVGs/iconeEdit.vue'
import iconeConcluida from '@/assets/SVGs/iconeConcluida.vue'
import iconeCheck from '@/assets/SVGs/iconeCheck.vue'

export default {
  components: {
		'icone-add': iconeAdd,
		'icone-add-user': iconeAddUser,
		'icone-add-empresa': iconeAddEmpresa,
		'icone-delete': iconeDelete,
		'icone-download': iconeDownload,
		'icone-link': iconeLink,
		'icone-edit': iconeEdit,
		'icone-concluida': iconeConcluida,
		'icone-check': iconeCheck,
  },
  props: {
    icone: {
      type: String,
      required: true
    },
    texto: {
      type: String,
      required: true
    },
    tipo: {
      type: String,
      required: false
    }
  },
  methods: {
    emitirClick() {
      this.$emit('buttonClick')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'Button.scss';
</style>