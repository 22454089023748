import { createStore } from 'vuex';
import plugins from './plugins'
import EventService from '../services/EventService'
import { insertToken, removeToken } from '../services/auth'

import { UserLocalStorage } from '@/utils/schemas'

export default createStore({
  state: {
    loading: false,
    user: UserLocalStorage,
    navExpanded: true, 
  },
  mutations: {
    LOGIN_USER(state, data) {
      state.user = {
        isLoggedIn: true,
        id: data.user._id,
        email: data.user.email,
        setor: data.user.setor,
        nome: data.user.nome,
        permissoes: data.user.permissoes,
      }
      insertToken(data.token)
    },
    UPDATE_USER(state, data) {
      state.user = {
        isLoggedIn: true,
        id: data.user._id,
        email: data.user.email,
        setor: data.user.setor,
        nome: data.user.nome,
        permissoes: data.user.permissoes,
      }
    },
    LOGOUT_USER(state) {
      state.user = UserLocalStorage
      removeToken()
    },
    LOADING(state, data) {
      state.loading = data.loading
    },
    NAVBAR(state, data) {
      if(!data)
        state.navExpanded = !state.navExpanded
      else
        state.navExpanded = data.navExpanded
    },
  },
  actions: {
    async login({ commit }, event) {
      try {
        const data = await EventService.login(event);
        commit('LOGIN_USER', data);
      } catch (error) {
        throw (error);
      }
    },
    logout({ commit }) {
      commit('LOGOUT_USER')
    },
    async validateToken({ commit }) {
      try {
        const data = await EventService.validateToken();
        if(!data) commit('LOGOUT_USER');
        else commit('UPDATE_USER', data);
      } catch (error) {
        throw (error);
      }
    },
    loading({ commit }) {
      document.querySelector('body').style.overflow = 'hidden'
      commit('LOADING', { loading: true })
    },
    notLoading({ commit }) {
      document.querySelector('body').style.overflow = ''
      commit('LOADING', { loading: false })
    },
    changeNavState({ commit }, event) {
      commit('NAVBAR', event)
    },

    async updateProfile({ commit }, event) {
      try {
        const data = await EventService.editPerfil(event);
        if(data) {
          commit('UPDATE_USER', data);
          insertToken(data.token)
        }
      } catch (error) {
        throw (error);
      }
    },
  },
  modules: {
  },
  getters: {
  },
  plugins,
});
