import Home from '../views/private/Home/Home.vue';
import Perfil from '../views/private/Perfil/Perfil.vue';

const Usuarios = () => import('../views/private/Usuarios/Usuarios.vue')
const Links = () => import('../views/private/Links/Links.vue')
const Demandas = () => import('../views/private/Demandas/Demandas.vue')
const Demanda = () => import('../views/private/Demandas/Demanda.vue')

export default [{
    path: '/',
    name: 'Home',
    component: Home,
}, {
    path: '/usuarios',
    name: 'Usuários',
    component: Usuarios,
    meta: { key: 'usuarios' }
}, {
    path: '/links',
    name: 'Links',
    component: Links,
    meta: { key: 'links' }
}, {
    path: '/demandas',
    name: 'Demandas',
    component: Demandas,
    meta: { key: 'demandas' },
}, {
    path: '/demanda/detalhes/:id',
    name: 'Demanda',
    component: Demanda,
    meta: { key: 'demandas' },
}, {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
}]
