<template>
  <footer>
    <div class="grid_cont_footer">
      <div class="grid_item_footer grid_logo">
        <div class="div_grid_footer">
          <img
            id="image_logo"
            src="@/assets/logo-startlaw-branca.png"
          />
        </div>
      </div>

      <div class="grid_item_footer grid_info">
        <div class="div_grid_footer infos">
          <a
            href="https://thestartlaw.com/sobre-nos/"
            target="_blank"
            title="Saiba mais sobre nós"
          >
            <span class="text_footer">Sobre nós</span>
          </a>
          <a
            href="https://thestartlaw.com/faq/"
            target="_blank"
            title="Esclareça suas dúvidas"
          >
            <span class="text_footer">FAQ</span>
          </a>

          <p class="text_footer italic">suporte@thestartlaw.com</p>

          <div id="direitos">
            <p class="direitos_footer italic">Todos os direitos reservados a</p>
            <p class="direitos_footer italic forte">STARTLAW</p>
            <p class="direitos_footer italic">
              - Serviço de Apoio a Empresa 2019-2020
            </p>
          </div>
        </div>
      </div>

      <div class="grid_item_footer grid_redes">
        <div class="div_grid_footer">
          <div class="row">
            <div class="col-">
              <a
                href="https://www.facebook.com/oficialstartlaw/"
                target="_blank"
              >
                <img
                  class="icone_rede"
                  src="https://i.imgur.com/nUwIKvQ.png"
                  title="Nosso Facebook"
                />
              </a>
            </div>
            <div class="col-">
              <a href="https://www.instagram.com/startlaw_/" target="_blank">
                <img
                  class="icone_rede"
                  src="https://i.imgur.com/uDFwo4g.png"
                  title="Nosso Instagram"
                />
              </a>
            </div>
            <div class="col-">
              <a
                href="https://www.linkedin.com/company/start-law/"
                target="_blank"
              >
                <img
                  class="icone_rede"
                  src="https://i.imgur.com/AOkqFEC.png"
                  title="Nosso LinkedIn"
                />
              </a>
            </div>
            <div class="col-">
              <a
                href="https://open.spotify.com/show/0ojerSHyh9pbwAVJ8imsXF?si=1OnfQjZqSB2qx5F3sWbmaQ"
                target="_blank"
              >
                <img
                  class="icone_rede"
                  src="https://i.imgur.com/xuwsQEa.png"
                  title="Nosso Podcast"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
@import "/FooterLogin.scss";
</style>