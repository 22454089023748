<template>
  <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="copy" role="img" 
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" 
    class=""
  >
  <g class="fa-group">
    <path fill="currentColor" d="M352 96V0H152a24 24 0 0 0-24 24v368a24 24 0 0 0 24 24h272a24 24 0 0 0 24-24V96z" 
      class="fa-secondary"></path>
    <path fill="currentColor" d="M96 392V96H24a24 24 0 0 0-24 24v368a24 24 0 0 0 24 24h272a24 24 0 0 0 24-24v-40H152a56.06 56.06 0 0 1-56-56zM441 73L375 7a24 24 0 0 0-17-7h-6v96h96v-6.06A24 24 0 0 0 441 73z" 
      class="fa-primary"></path>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg:hover{
  opacity: 0.6;
}
</style>