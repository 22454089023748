<template>
  <header>
    <div class="grid_cont_header">
      <div class="grid_item_header">
        <div class="div_grid_header">
          <img
            id="image_name"
            src="https://thestartlaw.com/wp-content/uploads/2020/03/startlaw-branca.png"
          />
        </div>
      </div>

      <div class="grid_item_header">
        <div class="div_grid_header">
          <h1 id="title">Link não encontrado ⚠️</h1>
        </div>
      </div>
    </div>
  </header>

  <main class="container">
    <section id="sec_nome_empresa">
      <p class="texto_nome forte grandao">Ops!</p>
    </section>

    <section id="sec_nome_empresa" style="margin: 0rem">
      <p class="texto_nome" style="font-size: 18pt">
        Pelo visto, esse link não existe!
      </p>
    </section>

    <section id="sec_nome_empresa" style="margin: 4rem">
      <p class="texto_nome" style="font-size: 22pt; font-weight: 500">
        Revise se o endereço que você esta acessando é o correto.
      </p>
    </section>

    <section id="sec_nome_empresa" style="margin: 4rem">
      <p class="texto_nome" style="font-size: 16pt">
        Caso o erro persista, não exite em contactar nossa equipe! Atenderemos
        você
      </p>
    </section>
  </main>

  <footer-login />
</template>

<script>
import Footer from '../../../components/HeaderFooterLogin/FooterLogin.vue'

export default {
	components: {
		'footer-login': Footer,
	}
}
</script>

<style lang="scss" scoped>
@import "/404.scss";
</style>