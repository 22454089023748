<template>
  <div id="area_tabela">
    <table>
      <thead>
        <tr>
          <th v-if="!remover_check"></th>
          <!-- <th style="min-width: 300px;">Título</th>
          <th style="width: 350px;">URL</th> -->
          <th id="th_titulo">Título</th>
          <th id="th_url">URL</th>
          <th id="th_atualizacao">Atualização</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="link in links" 
          :key="link._id"
          v-show="filtroTitulo(link)"
        >
          <td v-if="!remover_check" class="acao">
            <label class="container_check">
              <input class="soli_selecionada" :value="link._id" type="checkbox"
                v-model="selecionados" @change="emmitChange"
              >
              <span class="checkmark"></span>
            </label>
          </td>

          <td>{{link.titulo}}</td>

          <td>
            <div class="td_link">
              <button @click="copyLink(link._id)" title="Copiar link" class="clear_button">
                <icone-copy />
              </button>
              <input type="text"  
                :id="'link'+link._id+setor" 
                :value="link.url"
                class="sr-only hid"
              />
              <a :href="link.url" target="_blank" >
                <p v-if="link.url.length > 30">{{link.url.substring(0, 27)}}...</p>
                <p v-else>{{link.url}}</p>
              </a>
            </div>
          </td>

          <td>{{stringifyDate(link.atualizacao)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Checkmark from '@/components/Checkmark/Checkmark.vue'
import Copy from '@/assets/SVGs/iconeCopy.vue'
import { pegar_DATA_HORA } from '@/utils/dates'

export default {
  components: {
    'checkmark': Checkmark,
    'icone-copy': Copy,
  },
  data() {
    return {
      selecionados: [],
    }
  },
  props: {
    setor: {
      type: String,
      required: false
    },
    links: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      required: true
    },
    remover_check: {
      required: false,
      type: Boolean
    },
    remove_filter: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    emmitChange(event) {
      this.$emit('checkmarkChange', { id: event.target.value, state: event.target.checked })
    },
    filtroTitulo(link) {
      if(this.remove_filter) return true

      const titulo = link.titulo.toUpperCase()

      const filtro_titulo = this.filter.titulo.toUpperCase()

      const flag_titulo = titulo.includes(filtro_titulo)

      return flag_titulo
    },
    stringifyDate(data) {
      return pegar_DATA_HORA(data)
    },
    copyLink(id) {
      const copyText = document.querySelector('#link'+id+this.setor)

      copyText.select();
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy");

      this.$Swal.mixin({
        toast: true,
        position: 'top',
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$Swal.stopTimer)
          toast.addEventListener('mouseleave', this.$Swal.resumeTimer)
        }
      }).fire({
        icon: 'success',
        title: 'Link copiado!'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../Tables.scss';
@import 'Links.scss';
</style>