<template>
  <header>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
    <div class="grid_cont_header">
      <div class="grid_item_header">
        <div class="div_grid_header_image">
          <img id="image_name" 
					src="@/assets/startlaw_branca.png"/>
        </div>
      </div>

      <div class="grid_item_header">
        <div class="div_grid_header_text">
          <h1 id="title">{{ titulo }}</h1>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/HeaderLogin.scss";
</style>