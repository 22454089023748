import api from './api'

export default {
	async fetchDemandasUser() {
		try {
			const { data } = await api.get('/demandas/user')
			return data
		} catch (e) {
			throw (e)
		}
	},
	async fetchDemanda(event) {
		try {
			const { data } = await api.get(`/demandas/single/${event.id}`)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async createDemanda(event) {
		try {
			const { data } = await api.post('/demandas', event)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async deleteDemandas(event) {
		try {
			let query = `?`
			event.ids.forEach(id => query += `ids=${id}&`)
			const { data } = await api.delete(`/demandas${query}`)
			return data
		} catch (e) {
			throw (e)
		}
	},
	async editDemanda(event) {
		try {
			const { data } = await api.put('/demandas', event)
			return data
		} catch (e) {
			throw (e)
		}
	}
}