<template>
  <nav :class="{ 'expanded-nav': expandedNav }">
    <ul class="navbar-nav">
      <li class="logo">
        <a href="#" class="nav-link"
          @click="openUp()" title="Abrir/Fechar barra de navegação"
        >
          <!-- <img class="link-text logo-image" :class="{ 'expanded-nav': expandedNav }"
            src="/assets/logo-apolo-branca.png" alt="Logo da Apolo"
          /> -->
					<p class="link-text logo-image" :class="{ 'expanded-nav': expandedNav }">CENTRAL</p>

          <svg aria-hidden="true" focusable="false" data-prefix="fad"
            data-icon="bars" role="img" aria-label="Abrir/Fechar barra de navegação"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
            class="diferentona" :class="{ 'expanded-nav': expandedNav }"
          >
            <g class="fa-group">
              <path
                fill="currentColor" style="color: #eaeaea"
                d="M208 065H16A16 16 0 0 0 0 80v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z"
              ></path>
              <path
                fill="currentColor" style="color: #eaeaea"
                d="M16 288h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z"
              ></path>
              <path
                fill="currentColor" style="color: #eaeaea"
                d="M432 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
              ></path>
            </g>
          </svg>
        </a>
      </li>

      <li class="nav-info" :class="{ 'expanded-nav': expandedNav }">
        <div class="secao-perfil w-container">
          <div class="area-info">
            <h3 class="heading" id="user_nome">{{ user.nome }}</h3>
            <h5 class="heading-2" id="user_email">{{ user.email }}</h5>
          </div>
        </div>
      </li>

      <li class="nav-item">
				<router-link to="/" class="nav-link" title="Home">
          <icone-home />
          <span class="link-text" :class="{ 'expanded-nav': expandedNav }">Home</span>
				</router-link>
      </li>

      <li v-if="user.permissoes.usuarios" class="nav-item">
				<router-link to="/usuarios" class="nav-link" title="usuarios">
          <icone-usuarios />
          <span class="link-text" :class="{ 'expanded-nav': expandedNav }">Usuários</span>
				</router-link>
      </li>

      <li v-if="user.permissoes.links" class="nav-item">
				<router-link to="/links" class="nav-link" title="Links">
          <icone-linked />
          <span class="link-text" :class="{ 'expanded-nav': expandedNav }">Links</span>
				</router-link>
      </li>

      <li v-if="user.permissoes.demandas" class="nav-item">
				<router-link to="/demandas" class="nav-link" title="Demandas">
          <icone-demandas />
          <span class="link-text" :class="{ 'expanded-nav': expandedNav }">Demandas</span>
				</router-link>
      </li>

      <li class="nav-item">
				<router-link to="/perfil" class="nav-link" title="Perfil">
          <icone-perfil />
          <span class="link-text" :class="{ 'expanded-nav': expandedNav }">Perfil</span>
				</router-link>
      </li>

			<li class="nav-item">
				<a href="#" class="nav-link" @click="logout()" title="Sair">
					<icone-sair />
					<span class="link-text" :class="{ 'expanded-nav': expandedNav }">Sair</span>
				</a>
			</li>

      <li class="nav-item" id="saida-mobile"
        :class="{ 'inactive': bigMobileCheck }"
      ></li>

      <li class="nav-item">
        <div class="powered_by nav-link link-text" :class="{ 'expanded-nav': expandedNav }">
          <p style="display: block; text-align: end; margin: 2rem 1rem 1.5rem 0" >
            Powered by STARTLAW®
          </p>
        </div>
      </li>
    </ul>
  </nav>
  <div id="area-nav-body"></div>
</template>

<script>
import iconeHome from '../../assets/SVGs/iconeHome.vue' 
import iconeUsuarios from '../../assets/SVGs/iconeUsuarios.vue' 
import iconeLinked from '../../assets/SVGs/iconeLinked.vue' 
import iconePerfil from '../../assets/SVGs/iconePerfil.vue' 
import iconeSair from '../../assets/SVGs/iconeSair.vue' 
import iconeEmail from '../../assets/SVGs/iconeEmail.vue' 
import iconeDemandas from '../../assets/SVGs/iconeDemanda.vue' 

import mobileCheck from '../../utils/mobileCheck'

export default {
	components: {
		'icone-home': iconeHome,
		'icone-usuarios': iconeUsuarios,
		'icone-linked': iconeLinked,
		'icone-perfil': iconePerfil,
		'icone-sair': iconeSair,
		'icone-email': iconeEmail,
    'icone-demandas': iconeDemandas,
	},
  data() {
    return {
      resize: {
        debounce: false,
      },
      bigMobileCheck: false,
    }
  },
	computed: {
		user() {
			return this.$store.state.user
		},
		userAvatar() {
      const path = process.env.NODE_ENV == 'local' ? 
        'http://localhost:3500/avatars/' : 
        'https://api-agnes.thestartlaw.app/avatars/'
			return path + this.$store.state.user.id + '.png'
		},
		expandedNav() {
			return this.$store.state.navExpanded
		},
	},
	methods: {
		openUp() {
			this.$store.dispatch('changeNavState')
		},
		logout() {
			this.$store.dispatch('logout')
      this.$router.push('/login')
      
      this.$Swal.mixin({
        toast: true,
        position: 'top',
        timer: 10000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$Swal.stopTimer)
          toast.addEventListener('mouseleave', this.$Swal.resumeTimer)
        }
      }).fire({
        icon: 'info',
        title: 'Logout efetuado.'
      })
		},
    resizeHandler() {
      if(this.debounce) return 

      this.debounce = true
      setTimeout(() => {
        this.debounce = false
      }, 100);

      if(window.innerWidth <= 700)
        this.$store.dispatch('changeNavState', { navExpanded: false })
    },
    orientationHandler() {
      if(window.innerWidth <= 700)
        this.bigMobileCheck = false
      else// if(window.innerWidth > 700)
        this.bigMobileCheck = true
    }
	},

  created() {
    window.addEventListener("resize", this.resizeHandler);

    const mobile_flag = mobileCheck()

    if(mobile_flag) {
      window.addEventListener("orientationchange", () => {
        if(window.innerWidth <= 700)
          this.bigMobileCheck = false
        else// if(window.innerWidth > 700)
          this.bigMobileCheck = true
      });
    }

    if(window.innerWidth <= 700)
      this.bigMobileCheck = false
    else// if(window.innerWidth > 700)
      this.bigMobileCheck = true
      
		if(window.innerWidth <= 700)
      this.$store.dispatch('changeNavState', { navExpanded: false })
		else if(this.expandedNav){
      this.$store.dispatch('changeNavState', { navExpanded: true })
		}
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("orientationchange", () => {
      if(window.innerWidth <= 700)
        this.bigMobileCheck = false
      else// if(window.innerWidth > 700)
        this.bigMobileCheck = true
    });
  },
};
</script>

<style lang="scss" scoped>
@import './NavBar.scss';
</style>