import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import SweetAlert2 from './services/SweetAlert2'

createApp(App)
  .use(store)
  .use(router)
  .use(SweetAlert2)
.mount('#app');