export default {
  props: {
    filter: {
      required: false,
      type: Object
    }
  },
  methods: {
    filtro(demanda) {
      if(!this.filter) return true

      const titulo = demanda.titulo.toUpperCase()
      const filtro_titulo = this.filter.titulo.toUpperCase()
      const flag_titulo = titulo.includes(filtro_titulo)

      const status = demanda.status.toUpperCase()
      const filtro_status = this.filter.status.toUpperCase()
      const flag_status = status.includes(filtro_status)

      const time_prazo = new Date(demanda.prazo).getTime()
      let flag_prazo = true
      const agora = new Date().getTime()

      if(this.filter.prazo != 'data'){
        const diferenca = (agora - time_prazo)/(3600000*24)

        switch(this.filter.prazo) {
          case 'Mais de um dia':
            flag_prazo = diferenca < -1
            break;
          case 'Amanhã':
            flag_prazo = diferenca > -1 && diferenca < 0
            break;
          case 'Passados':
            flag_prazo = diferenca > 0
            break;
        }
      }
      else {
        let time_inicial = 0
        if(this.filter.prazo_datas.inicial)
          time_inicial = new Date(this.filter.prazo_datas.inicial).getTime()

        let time_final = agora + (365*24*3600000)
        if(this.filter.prazo_datas.final)
          time_final = new Date(this.filter.prazo_datas.final).getTime() + 23*3600000 + 59*60000

        flag_prazo = time_inicial < time_prazo && time_prazo < time_final
      }

      return flag_titulo && flag_status && flag_prazo
    },
  }
}