const handlers = {}

//Formatação de valores  
const verifyDD = (info) => {
    return info<10 ? `0${info}` : info;
}
handlers.verifyDD = (info) => {
    return info<10 ? `0${info}` : info;
}

handlers.pegar_data_atual = (date) => {
    var agora = date || (Date.now());
    var dia_mes_ano = new Date(agora);
    var dia_atual = dia_mes_ano.getDate(), mes_atual = dia_mes_ano.getMonth()+1, ano_atual = dia_mes_ano.getFullYear();
    dia_mes_ano = `${verifyDD(dia_atual)}/${verifyDD(mes_atual)}/${verifyDD(ano_atual)}`;
    return dia_mes_ano;
}             
handlers.pegar_data_atual_hif = (date) => {
    var agora = date || (Date.now());
    var dia_mes_ano = new Date(agora);
    var dia_atual = dia_mes_ano.getDate(), mes_atual = dia_mes_ano.getMonth()+1, ano_atual = dia_mes_ano.getFullYear();
    dia_mes_ano = `${verifyDD(dia_atual)}-${verifyDD(mes_atual)}-${verifyDD(ano_atual)}`;
    return dia_mes_ano;
}

handlers.pegar_hm_atual = (date) => {
    var agora = date || (Date.now());
    var hora_minuto = new Date(agora);
    hora_minuto = `${verifyDD(hora_minuto.getHours())}:${verifyDD(hora_minuto.getMinutes())}`
    return hora_minuto;
}

handlers.parse_minha_data = (data) => {
    return {
        dia: parseInt(verifyDD(parseInt(data.slice(0, 2)))),
        mes: parseInt(verifyDD(parseInt(data.slice(3, 5)))),
        ano: parseInt(verifyDD(parseInt(data.slice(6, 10))))
    }
}
    
handlers.inverter_mm_dd = (data) => {
    data = data.substring(0, 10)
    return `${data.substring(3, 5)}/${data.substring(0, 2)}/${data.substring(6, 10)}`.trim()
}

handlers.pegar_DATA_HORA = (data, offset) => {
    const horas_offset = offset | 0
    const data_brazil = new Date(data).getTime() + (horas_offset*3600000)
    return `${handlers.pegar_data_atual(data_brazil)} (${handlers.pegar_hm_atual(data_brazil)})`
}

module.exports = handlers; 