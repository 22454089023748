<template>
  <section class="container-info">
    <div id="informations">
      <div class="form-signin">
        <div class='area_logo'>
          <p id="titulo_agnes">CENTRAL STARTLAW</p>
          <!-- <img class='logo_apolo' src="https://thestartlaw.com/wp-content/uploads/2020/07/LOGO_APOLO_PRETA.png" alt="Logo Apolo"> -->
        </div>
        
        <p v-if="error">{{ error }}</p>

        <form @submit.prevent="onSubmit" class="form-area">
          <div class="form-group">
            <label class="control-label" for="email">E-mail</label>
            <input 
              placeholder="E-mail"
              v-model="loginData.email"
              @input="clearError"
              autocomplete="none" 
              id="email" 
              type="email" 
              class="form-control" 
              name="email" 
              required 
            >
          </div>

          <button type="submit" class="btn btn-lg btn-dark btn-block">Restaurar senha</button>
        </form>

          <div class="reset_senha">
            <div class="area_reset_senha">
              <router-link to="/login">Voltar para login</router-link>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import EventService from '@/services/EventService'
import Header from '@/components/HeaderFooterLogin/HeaderLogin.vue'
import Footer from '@/components/HeaderFooterLogin/FooterLogin.vue'

export default {
  name: 'Login',
  components: {
    'header-login': Header,
    'footer-login': Footer,
  },
  data () {
    return {
      loginData: {
        email: '',
      },
      error: null,
    }
  },
  methods: {
	  onSubmit() {
      const event = {
        ...this.loginData,
      }
      EventService.passwordReset(event)
	    .then(() => {
        this.$store.dispatch('notLoading')
        this.$Swal.fire('Senha atualizada! Acesse seu e-mail para buscá-la.')
        this.$router.push('/login')
	    })
      .catch( error => {
        this.$store.dispatch('notLoading')
        const msg_error = error?.response?.data.error || error.message
        if (msg_error == "Network Error")
          return this.$router.push('/servico-indisponivel')
        this.error = msg_error
      })
	  },
    clearError() {
      this.error = null
    }
  }
}
</script>

<style lang="scss">
@import '/../PreLogin/PreLogin.scss';
@import '/ResetPassword.scss';
</style>