<template>
  <svg aria-hidden="true" focusable="false" data-prefix="fad" 
						data-icon="sign-out" role="img" aria-label="Ícone Sair"
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" 
					>
						<g class="fa-group">
							<path fill="currentColor" d="M180 448H96a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H96a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12z" 
								class="fa-secondary"
							></path>
							<path fill="currentColor" d="M353 88.3l151.9 150.6a24 24 0 0 1 0 34.1l-152 150.8a24.08 24.08 0 0 1-33.9-.1l-21.9-21.9a24.07 24.07 0 0 1 .8-34.7l77.6-71.1H184a23.94 23.94 0 0 1-24-24v-32a23.94 23.94 0 0 1 24-24h191.5l-77.6-71.1a24 24 0 0 1-.7-34.6l21.9-21.9a24 24 0 0 1 33.9-.1z" 
								class="fa-primary"
							></path>
						</g>
					</svg>
</template>