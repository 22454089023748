<template>
  <router-view/>
  <loading-logo />
</template>

<script>
import socket from "@/services/socket";

import LoadingLogo from './components/LoadingLogo/LoadingLogo.vue'
export default {
  components: {
    'loading-logo': LoadingLogo
  },

  data() {
    return {
      socketInstance: {},
      audio: new Audio('/notification.mp3'),
    }
  },

  mounted() {
    this.socketInstance = socket

    this.socketInstance.on("aviso:notificacao", (data) => {
      if(data.socket_id == this.socketInstance.id) return

      this.audio.play()

      this.notificacaoSwal('Novo aviso postado no mural!')
    })

    this.socketInstance.on("demanda:notificacao", (data) => {
      if(data.socket_id == this.socketInstance.id) return
      
      const responsavel = data.responsaveis.includes(this.$store.state.user.id)
      if(!responsavel) return

      this.audio.play()

      this.notificacaoSwal('Você é responsável por uma nova demanda!')
    })
  },

  methods: {
    notificacaoSwal(mensagem) {
      this.$Swal.mixin({
        toast: true,
        position: 'top',
      }).fire({
        icon: 'info',
        title: mensagem
      })
    }
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
