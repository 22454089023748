<template>
  <nav-bar />
  <div class="area-main"></div>

  <main :class="{ 'expanded-nav': expandedNav }">
    <router-view />
    <img v-if="this.$store.state.user.isLoggedIn" id="icone_faded" src="@/assets/logo-branca.png" >
  </main>
</template>

<script>
import NavBar from "../../../components/NavBar/NavBar.vue";

export default {
  components: {
    "nav-bar": NavBar,
  },
	computed: {
		expandedNav() {
			return this.$store.state.navExpanded
		},
	},
  created() {
    this.$store.dispatch('notLoading')
  }
};
</script>

<style lang="scss" scoped>
@import "/UserTemplate.scss";
</style>